.pagination-page {
  > ul {
    display: flex;
    justify-content: center;

    > li {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      > .page-numbers {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6rem;
        min-width: 6rem;
        color: $primary;
        text-decoration: none;
        border: 1px solid $preto10;
        border-radius: 5px;

        &.current {
          color: $white;
          background-color: $primary;
          border: none;
        }

        &.next,
        &.prev {
          padding: 0 1.8rem;
        }
      }
    }
  }
}
