.aside-app {
  background-color: $preto05;
  padding: 1.5rem;
  box-shadow: 0 1px 2px rgba($black, .2);
  border-radius: 1rem;

  > .menu {
    > ul {
      > li {
        margin-bottom: 1rem;

        &.current-menu-item,
        &.current-page-ancestor {
          > a {
            color: $white;
            background-color: $primary;
          }
        }

        > a {
          display: block;
          padding: 1.5rem;
          background-color: $white;
          border: 1px solid $primary;
          border-radius: 5px;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: bold;
          color: $primary;
          transition: .3s;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  > .lastestnews {
    > .new {
      > .list {
        > .item {
          display: flex;
          border-bottom: 1px solid $preto10;
          padding: 1.5rem 0;

          &:first-child {
            padding-top: 0;
          }

          > .imagebox {
            margin-right: 1.5rem;
    
            > .image {
              width: 7.8rem;
              height: 7.8rem;
              object-fit: cover;
            }
          }
          > .text {
            > .title {
              font-size: 1.6rem;
              line-height: 1.62;
              font-weight: bold;
    
              > .link {
                color: $primary;
                text-decoration: none;
    
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}