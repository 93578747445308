.button-app {
  display: inline-block;
  width: auto;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 2rem 3rem;
  transition: 0.2s;
  letter-spacing: 0.75px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba($black, .2);

  &.-sm {
    padding: 1.3rem 1.5rem;
  }

  &.-primary {
    color: $white;
    background-color: $primary;

    &:hover {
      background-color: $primary3;
    }
  }

  &.-secondary {
    color: $primary;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: transparent;
      border-color: $white;
    }
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.9);
  }
}
