.card-app {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-card;
  margin-bottom: 3rem;
  background-color: $white;

  > .menu {
    > ul {
      > li {
        margin-bottom: 1rem;

        &.current-menu-item,
        &.current-page-ancestor {
          > a {
            color: $white;
            background-color: $primary;
          }
        }

        > a {
          display: block;
          padding: 1.5rem;
          background-color: $white;
          border: 1px solid $primary;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: bold;
          color: $primary;
          transition: .3s;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  &.-p3rem {
    padding: 3rem;
  }

  &.-nomargin {
    margin: 0;
  }

  &.-filial {
    > .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      box-shadow: $shadow-card-elevado;
      margin: 0 auto 1.5rem;

      > img {
        height: 6rem;
      }
    }

    > .address, > .phones, > .email {
      margin-bottom: 1.5rem;

      &.-nomargin {
        margin: 0;
      }
    }
  }

  &.-service {
    text-align: center;
    margin-bottom: 3rem;
    background-color: transparent;
    box-shadow: none;

    > .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $preto100;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        > .text {
          > .excerpt {
            text-decoration: underline;
          }
        }
      }

      > .icon {
        width: 9rem;
        height: 9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        border-radius: 50%;
        margin-bottom: 1.5rem;

        > img {
          width: 6rem;
          height: 6rem;
        }
      }

      > .text {
        flex: 1;

        > .title-app {
          color: $secondary;
        }
      }
    }
  }

  &.-news {
    text-decoration: none;
    transition: .2s;

    &:hover {
      transform: scale(1.1);
      box-shadow: $shadow-card-elevado;
      z-index: 100;
    }
  
    > .imagebox {
  
      > .image {
        width: 100%;
      }
    }
  
    > .text {
      padding: 3rem;
    }
  }

  &.-aside {
    > .lastestnews {
      > .new {
        > .list {
          > .item {
            display: flex;
            border-bottom: 1px solid $preto10;
            padding: 1.5rem 0;
  
            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              border-bottom: none;
            }
  
            > .imagebox {
              margin-right: 1.5rem;
      
              > .image {
                width: 7.8rem;
                height: 7.8rem;
                object-fit: cover;
              }
            }
            > .text {
              > .title {
                font-size: 1.6rem;
                line-height: 1.62;
                font-weight: bold;
      
                > .link {
                  color: $primary;
                  text-decoration: none;
      
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

.-home {
  .card-app {
    &.-service {
      > .link {
        > .text {
          > .excerpt {
            color: $white;
          }
        }
      }
    }
  }
}