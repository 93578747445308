.header-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.5rem;
  background-color: $primary;
  z-index: 100;
  box-shadow: 0 4px 8px rgba($black, 0.2);

  > .container {

    > .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .logo {
        position: relative;
        background-color: $white;
        padding: 3rem 1.5rem 1rem 0;

        &::before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 6000px;
          height: 100%;
          background-color: $white;
          box-shadow: $shadow-card-elevado;
        }

        &::after {
          display: block;
          content: '';
          background: url('images/after-logo-header.svg') no-repeat center / cover;
          position: absolute;
          top: 0;
          right: -4rem;
          width: 4rem;
          height: 100%;
        }

        > .link {
          position: relative;
          z-index: 1;

          > .image {
            height: 7.5rem;
          }
  
          &.desktop {
            @media screen and(max-width: $screen-md) {
              display: none;
            }
          }
  
          &.mobile {
            display: none;
  
            @media screen and(max-width: $screen-md) {
              display: block;
            }
          }
        }
      }
  
      > .nav {
        > .bars {
          font-size: 20px;
          color: $secondary;
          cursor: pointer;
        }
  
        > .menu {
          padding-top: 1.2rem;

          @media screen and (max-width: $screen-md) {
            position: fixed;
            width: 80%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: rgba($primary, 0.95);
            box-shadow: 0 8px 16px rgba($black, 0.4);
            transform: translate3d(110%, 0, 0);
            transition: 0.3s;
            overflow-y: scroll;
            z-index: 10;
            padding-top: 0;
  
            &.active {
              transform: translate3d(0, 0, 0);
            }
          }
  
          > .close {
            color: $white;
            font-size: 20px;
            padding: 15px;
            z-index: 10;
            text-align: right;
            cursor: pointer;
          }
  
          ul {
            display: flex;
  
            @media screen and (max-width: $screen-md) {
              flex-direction: column;
            }
  
            > li {
              > a {
                position: relative;
                display: block;
                text-decoration: none;
                font-size: 1.4rem;
                letter-spacing: 0.075rem;
                color: $white;
                padding: 1rem;
                transition: 0.3s;

                @media screen and (max-width: $screen-md) {
                  color: $white;
                }
  
                &::after {
                  display: none;
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 1rem;
                  height: 2px;
                  width: calc(100% - 2rem);
                  z-index: 1;
                  background-color: $secondary;

                  @media screen and (max-width: $screen-md) {
                    background-color: $secondary;
                  }
                }
  
                &:hover {

                  @media screen and (max-width: $screen-md) {
                    color: $white;
                  }
  
                  &::after {
                    display: block;
                  }
                }
              }
  
              &.current-menu-item,
              &.current-page-ancestor {
                > a {
                  color: $secondary;

                  @media screen and (max-width: $screen-md) {
                    color: $secondary;
                  }

                  &::after {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
