.time-app {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.43;
  color: $preto50;
  margin-bottom: 1.5rem;

  > svg {
    margin-right: .5rem;
  }
}