.title-app {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: $primary;

  &.-heading1 {
    font-size: 3.4rem;
    line-height: 1.46;
  }

  &.-heading2 {
    font-size: 2.4rem;
    line-height: 1.46;
  }

  &.-heading3 {
    font-size: 1.8rem;
    line-height: 1.46;
  }

  &.-section {

    &::after {
      display: block;
      content: "";
      width: 6rem;
      height: 6px;
      background-color: $primary;
      margin-top: 1rem;
    }
  }

  &.-center {
    text-align: center;
    
    &::after {
      margin: 0 auto;
    }
  }

  &.-ttnone {
    text-transform: none;
  }

  &.-white {
    color: $white;
  }

  &.-secondary {
    color: $secondary;
  }
}
