$white: #ffffff;
$black: #000000;

$preto100: #464646;
$preto50: #A3A3A3;
$preto30: #C7C7C7;
$preto10: #ECECEC;
$preto05: #F6F6F6;

$primary: #344A7D;
$primary1: #8091B7;
$primary2: #546798;
$primary3: #1F3567;
$primary4: #0B1E4A;

$secondary: #F2803A;

$mineShaft: #333333;
$mercury: #e5e5e5;
$gray: #808080;

$goldenGrass: #d6a626;
$salomie: #ffdc7f;
$butteredRum: #ab8112;
$brown: #846000;
