.breadcrumb-app {
  background-color: $preto05;
  padding: 3rem 0;
  font-size: 2rem;
  line-height: 1.5;
  border-bottom: 1px solid $preto10;

  @media screen and (max-width: $screen-sm) {
    overflow-y: auto;
  }

  ul {
    display: flex;

    > li {
      @media screen and (max-width: $screen-sm) {
        white-space: nowrap;
      }

      a {
        color: $primary;
        text-decoration: none;
      }

      &.separator {
        color: $primary;
      }
    }
  }
}