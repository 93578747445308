/* SLICK SLIDER */
.slider-depositions {
  .slick-prev {
    left: -6rem;
  }
  
  .slick-next {
    right: -6rem;
  }

  .slick-prev {
    background: url("./images/chevron-left-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-left-depositions.svg") center no-repeat;
    }
  }

  .slick-next {
    background: url("./images/chevron-right-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-right-depositions.svg") center no-repeat;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-dots {
  bottom: 1.5rem;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots li {
  &.slick-active {
    button {
      background-color: $primary;
    }
  }

  button {
    border: none;
    background-color: $preto30;
    border-radius: 20px;

    &::before {
      display: none;
    }
  }
}

/* CONTACT FORM 7 */

.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 1.5rem;
}

.wpcf7 form .wpcf7-response-output {
  margin: 1.5rem 0 !important;
}

.wpcf7 .ajax-loader, .wpcf7-spinner {
  position: absolute !important;
}

/* WORDPRESS */

.wp-block-image img {
  height: auto;
}
