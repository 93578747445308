.slider-app {
  position: relative;
  color: $white;

  &.-header {
    .slide {
      position: relative;
      display: flex !important;
      align-items: center;
      min-height: calc(100vh - 8.8rem);
      background: url("./images/bg-slide.png") center no-repeat;
      background-size: cover;
      padding: 6rem 0;
  
      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
      }
  
      &.slick-active {
        > .container {
          .content {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      }
  
      > .container {
        position: relative;
        z-index: 1;
  
        .content {
          opacity: 0;
          transition-duration: 1s;
          transition-delay: .4s;
          transform: translate3d(0, 3rem, 0);
  
          > .title {
            font-size: 6rem;
            font-weight: 700;
            line-height: 1.46;
            letter-spacing: -1%;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
          }
  
          > .text {
            font-size: 2rem;
            line-height: 1.5;
            margin-bottom: 3rem;
          }
        }
      }
    }

    .slick-prev {
      left: 3rem;
    }
    
    .slick-next {
      right: 3rem;
    }
    
    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 6rem;
      height: 6rem;
    
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
    
    .slick-prev {
      background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
      background-size: 1.5rem 3rem;
    
      &:hover, &:focus {
        background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
        background-size: 1.5rem 3rem;
      }
    
      &:hover {
        opacity: .8;
      }
    }
    
    .slick-next {
      background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
      background-size: 1.5rem 3rem;
    
      &:hover, &:focus {
        background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
        background-size: 1.5rem 3rem;
      }
    
      &:hover {
        opacity: .8;
      }
    }
  }

}
