.list-social {
  > .list {
    display: flex;

    > .item {
      margin-right: 3rem;

      > .link {
        font-size: 1.6rem;
        line-height: 1.625;
        color: $primary;

        &:hover {
          color: $primary3;
        }
      }
    }
  }
}
